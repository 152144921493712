<template>
  <div style="text-align: left;">
    <div>驳回对象：{{ username }}</div>
    <div style="margin-top: 20px">
      <el-select v-model="msgid" placeholder="请选择" @change="handleSelect">
        <el-option v-for="item in msgList" :key="item.id" :label="item.message" :value="item.id">
        </el-option>
      </el-select>
      <el-button style="margin: 0 12px;" type="primary" size="default" @click="handleClickEdit">
        编辑回复
      </el-button>
    </div>
    <div style="margin-top: 20px">
      <el-input
        v-model="inputMsg"
        placeholder="其他原因，手动输入"
        type="textarea"
        :autosize="{ minRows: 5 }"
      ></el-input>
    </div>
    <div v-if="type === 'achievement'" style="margin-top: 20px">
      在<el-select v-model="forbidden_hours" style="width: 180px;padding:0 6px;">
        <el-option label="12" value="12"> </el-option>
        <el-option label="24" value="24"> </el-option>
        <el-option label="36" value="36"> </el-option>
        <el-option label="720" value="720"> </el-option> </el-select
      >小时内禁止申请认证
    </div>
    <div style="margin-top: 40px">
      <el-button type="primary" size="default" @click="handleConfirm" :loading="loading">
        提交
      </el-button>
      <el-button size="default" @click="handleCancel">取消</el-button>
      <el-checkbox v-if="showBlockList" v-model="isBlock" style="margin: 0 8px 0 20px">
        驳回并将作品加入黑名单
      </el-checkbox>
      <el-checkbox v-if="type === 'achievement' && showRejectAll" v-model="reject_all" style="margin: 0 8px 0 20px">
        同时驳回他的其他申请
      </el-checkbox>
    </div>
    <el-dialog
      title="编辑快捷回复"
      :visible.sync="visible"
      width="30%"
      append-to-body
      @close="visible = false"
    >
      <EditRejectMsg :type="type" @update="fetchMsgList" :msgList="msgList" />
    </el-dialog>
  </div>
</template>

<script>
import { getRejectMsgList } from "@/api/nft.js";
import EditRejectMsg from "./EditRejectMsg.vue";

export default {
  components: {
    EditRejectMsg,
  },
  props: {
    username: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "achievement", // nft_on_shelf
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showBlockList: {
      type: Boolean,
      default: false,
    },
    showRejectAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      msgList: [],
      msgid: "",
      inputMsg: "",
      forbidden_hours:"",
      reject_all:"",
      visible: false,
      isBlock: false,
    };
  },
  mounted() {
    console.log("reject form mounted");
    this.fetchMsgList();
  },
  methods: {
    async fetchMsgList() {
      try {
        const { data } = await getRejectMsgList(this.type);
        this.msgList = data.data || [];
      } catch (error) {
        console.log(error);
      }
    },
    handleClickEdit() {
      this.visible = true;
    },
    handleSelect() {
      const selected = this.msgList.find((i) => i.id === this.msgid);
      if (selected) {
        this.inputMsg = selected.message;
      }
    },
    handleConfirm() {
      if (!this.inputMsg) {
        this.$message.error("请选择一个原因或手动输入原因");
        return;
      }
      if (this.showBlockList) {
        this.$emit("confirm", this.inputMsg, this.isBlock);
      } else {
        this.$emit("confirm", this.inputMsg, this.forbidden_hours, this.reject_all);
      }
    },
    handleCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
