<template>
  <div class="edit-reject-container">
    <div v-for="msg in msgList" :key="msg.id" style="margin: 0 0 8px 0;">
      <el-row
        :gutter="20"
        v-if="msg.id !== editID"
        style="height: 40px"
        align="middle"
        type="type"
        justify="space-between"
      >
        <el-col :span="18" :offset="0">
          <div style="line-height: 40px; height: 40px; overflow: hidden;">
            {{ msg.message }}
          </div>
        </el-col>
        <el-col :span="6" :offset="0">
          <div style="display: inline-flex">
            <div class="edit-button" style="">
              <i class="el-icon-edit" @click="handleEdit(msg)"></i>
            </div>
            <div class="edit-button">
              <i class="el-icon-delete" @click="handleDelete(msg.id)"></i>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-input v-else v-model="editMsg" placeholder="请输入" size="normal">
        <el-button
          slot="append"
          icon="el-icon-check"
          @click="handleConfirmEdit"
        ></el-button>
      </el-input>
    </div>
    <div style="margin-top: 80px">
      <el-row :gutter="20">
        <el-col :span="18" :offset="0">
          <el-input
            v-model="addMsg"
            placeholder="添加快捷回复"
            size="normal"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="6" :offset="0"
          ><el-button
            type="primary"
            icon="el-icon-plus"
            circle
            size="default"
            @click="handleAdd"
          >
          </el-button
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { setRejectMsg, deleteRejectMsg, addRejectMsg } from "@/api/nft.js";

export default {
  props: {
    msgList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editID: "",
      editMsg: "",
      addMsg: "",
    };
  },
  methods: {
    handleEdit(msg) {
      this.editID = msg.id;
      this.editMsg = msg.message;
    },
    async handleAdd(id) {
      const success = await this.add();
      success && this.success();
    },
    async handleDelete(id) {
      const success = await this.del(id);
      success && this.success();
    },
    async handleConfirmEdit() {
      const success = await this.edit();
      success && this.success();
    },
    success() {
      this.$emit("update");
      this.editID = "";
      this.editMsg = "";
      this.addMsg = "";
    },
    // 增删改
    async edit() {
      if (!this.editMsg) {
        this.$message.error("编辑快捷回复不能为空");
        return false;
      }

      try {
        const params = {
          id: this.editID,
          message: this.editMsg,
          type: this.type,
        };
        const { data } = await setRejectMsg(params);
        console.log(data);
        return true;
      } catch (error) {
        return false;
      }
    },
    async add() {
      if (!this.addMsg) {
        this.$message.error("新增快捷回复不能为空");
        return false;
      }

      try {
        const params = {
          message: this.addMsg,
          type: this.type,
        };
        const { data } = await addRejectMsg(params);
        console.log(data);
        return true;
      } catch (error) {
        return false;
      }
    },
    async del(id) {
      try {
        const params = {
          id,
          type: this.type,
        };
        const { data } = await deleteRejectMsg(params);
        console.log(data);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-reject-container {
  .edit-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
